import React from 'react'
import styled from 'styled-components'

const ApplicationOverStyles = styled.section`
    width: 100%;
    h1 {
        text-align: center;
        padding: 2rem 0;
        width: 90%;
        margin: 0 auto;
    }

    p {
        font-weight: bold;
        text-align: center;
        font-size: 1rem;
    }
`
const ApplicationOver = ({children}) => {
    return (
        <ApplicationOverStyles>
            {/* <h1>APPLICATIONS ARE NOW CLOSED</h1> */}
            {!children ? (
                <>
                    <p>The ‘Our First Show’ scheme for Disney’s THE LION KING enables school groups to purchase a limited number of £10 tickets to the musical on select performances.</p>
        
                    <p>Applications to take part in this scheme are currently closed – however information about new dates will be issued shortly.</p>
                </>
            ) : (
                children
            )}

        </ApplicationOverStyles>
    )
}

export default ApplicationOver
