import React, {Component} from "react"
import ReactDOM from "react-dom"
import {loadReCaptcha, ReCaptcha} from "recaptcha-v3-react"
import Layout from "components/Layout/Layout"
import styled from "styled-components";
import {Link, navigate} from "gatsby"
import Amplify, {API, Storage} from "aws-amplify"
import awsconfig from "../aws-exports.js"
import {media} from "utils/Media"
// import manchester from "images/Palace-theatre-manchester.svg"
import manchester from "images/LK-Home-1linelogo-lyceum-1.svg"
import Cookies from "js-cookie"
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input
} from "reactstrap"
import {
  validateRequired,
  validateEmail,
  validatePhone,
  validateAmount,
  validateRequiredCheckbox
} from "js/validations"
import iconDropDown from "images/icons/icon-drop-down.svg"
import ApplicationOver from "../components/shared/ApplicationOver";

const captchaSiteKey = "6Ld1Xa4UAAAAAAWXlgrYmeqKku8qw2apDa3jKpuZ"

Amplify.configure(awsconfig)

const HeaderWrapper = styled.div`
  margin: 0 auto 2rem;
  max-width: 1600px;
  width: 100%;
  background-color: ${props => props.theme.colors.yellow};
  text-align: center;
  padding: 0;
  position: relative;
  z-index: 2;

  @media ${media.md} {
    text-align: left;
  }

  .container {
    position: relative;
  }


`

const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  padding: 1rem 0;

  @media ${media.md} {
    flex-direction: unset;
    padding: 0;
    flex-wrap: no-wrap;
  }

  .items {
    width: 100%;
    @media ${media.md} {
      width: 50%;
    }
  }

  a {
    margin: 0 auto;
    display: block;

    @media ${media.md} {
      text-align: right;
    }
  }

  img {
    width: 190px;

    @media ${media.md} {
      width: 320px;
    }
  }

  h1 {
    margin: 0 auto;
    font-family: ${props => props.theme.font.family.bold};
    color: ${props => props.theme.colors.brown};

    @media (max-width: 767px) {
      font-size: 28px;
    }
  }

  p {
    margin: 0 auto;
    line-height: 100%;
    color: ${props => props.theme.colors.brown};
    @media (max-width: 767px) {
      font-size: 0.875rem;
    }
  }
`

const FormTheme = styled.div`
  label {
    font-size: 1.125rem;
    font-family: ${props => props.theme.font.family.light};
    width: 100%;
  }

  input:not([type="checkbox"]), select, textarea {
    background-color: ${props => props.theme.colors.superLightGrey};
    width: 100%;
    border-radius: 0;
    padding: 0 1rem;
    min-height: 3rem;
  }

  select {
    background-image: url(${iconDropDown});
    background-position: calc(100% - 0.625rem) center;
    background-size: 15px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .invalid-feedback {
    color: ${props => props.theme.colors.red};
    font-family: ${props => props.theme.font.family.base};
    font-size: 0.875rem !important;
    padding: 12px;
  }

  .red {
    color: ${props => props.theme.colors.red};
  }

  a {
    color: ${props => props.theme.colors.red};
  }

  .form-check:not(.no-border) {
    border: 1px solid ${props => props.theme.colors.grey};
    padding: 1rem;
    margin-bottom: 1rem;

    label {
      margin-left: 3rem;
    }

    input {
      left: -1rem;
    }
  }

  .form-check-input {
    top: 0;
    left: 20px;
    width: 20px;
    height: 20px;
  }

  label.form-check-label.checkbox-btn {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`

const SubmitButton = styled.button`
  width: 198px;
  height: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 200%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.yellow};
  transition: all 150ms ease-in-out;

  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.black};
  }
`

const CompetitionOver = styled.section`
  width: 100%;
  text-align: center;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;

  p {
    font-size: 20px;
    font-weight: 900;
  }
`

class MusicPlayDayForm extends Component {
  
  constructor(props) {
    super(props)
    
    this.state = {
      name: "",
      email: "",
      participantName: "",
      participantEmail: "",
      phoneNumber: "",
      consent: "",
      instrumentsPlayed: "",
      grade: "",
      questionForMusician: "",
      accessRequirements: "",
      googleVerified: "",
      
      // nameOfOrganisation: "Rock",
      // postcode: "W1W 6XX",
      // fullName: "David",
      // positionAtSchool: "Pupil",
      // email: "d.simon@dewynters.com",
      // phoneNumber: "+447415287832",
      // alternativeContactNumber: "07777777777",
      // howManyStudents: "1",
      // howManyTeachers: "2",
      // ageRanges: "1-4",
      // instrumentalTeachers: "Yes",
      // instrumentsPlayed: "Flute",
      // grade: "1",
      // choirOrSolo: "Choir",
      // questionForMusician: "No question",
      // pupilPremium: "10",
      // neverBeenToTheatre: "5",
      // googleVerified: "",
      validate: {
        // name: "",
        // email: "",
        emailErrMsg: "A valid email is required",
        participantName: "",
        participantEmail: "",
        phoneNumber: "",
        phoneErrMsg: "A valid UK phone number is required",
        // consent: "",
        instrumentsPlayed: "",
        grade: "",
        gradeErrMsg: "Grade is required",
        googleVerified: "",
        googleVerifiedErrMsg: "Google recaptcha is required"
      },
      success: false,
      cookieAccepted: true
    }
    
    // Bind this to methods
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onGoogleVerify = this.onGoogleVerify.bind(this)
    
    // Bind this to validation methods
    this.validateRequired = validateRequired.bind(this);
    this.validateEmail = validateEmail.bind(this);
    this.validatePhone = validatePhone.bind(this);
    this.validateAmount = validateAmount.bind(this);
    this.validateRequiredCheckbox = validateRequiredCheckbox.bind(this);
    
    // Create form ref
    this.form = React.createRef();
  }
  
  componentDidMount() {
    if (Cookies.get('TWDC-DTCI_ENSIGHTEN_PRIVACY_TWDC_Vendor_Consent') !== '1') {
      this.setState({
        cookieAccepted: true
      })
    }
  
  
    if (Cookies.get('TWDC-DTCI_ENSIGHTEN_PRIVACY_TWDC_Vendor_Consent') === '1') {
      const script = document.createElement("script")
      script.src = `https://www.google.com/recaptcha/api.js?render=${captchaSiteKey}`
      script.addEventListener("load", this.onGoogleVerify)
      document.body.appendChild(script)
    }
    
    const that = this
    
    if (typeof window.grecaptcha !== "undefined" && Cookies.get('TWDC-DTCI_ENSIGHTEN_PRIVACY_TWDC_Vendor_Consent') === '1') {
      window.grecaptcha.ready(function () {
        window.grecaptcha.execute(captchaSiteKey, {action: "validate_captcha"}).then(function (token) {
          console.log(token);
          that.onGoogleVerify(token)
        });
        // refresh token every minute to prevent expiration
        setInterval(function () {
          window.grecaptcha.execute(captchaSiteKey, {action: "validate_captcha"}).then(function (token) {
            console.log("refreshed token:", token);
            that.onGoogleVerify(token)
          });
        }, 60000);
        
      });
    }
  }
  
  handleChangeNumbers = (evt, stateName, stateValue) => {
    const value = (evt.target.validity.valid) ? evt.target.value : stateValue;
    
    this.setState({[stateName]: value});
  }
  
  async onGoogleVerify(response) {
    const {validate} = this.state;
    const myInit = {
      headers: {},
      body: {
        recaptchaResponse: response,
      },
    };
    
    const res = await API.post("LKEducationAPI", "/LKRecaptcha", myInit)
      .then((response) => {
        // If there are errors update validation state
        console.log(response)
        if (response.event.success === false || response.event.score < 0.5) {
          validate.googleVerified = "has-danger"
          console.log("FAILED")
          this.setState({
            validate,
            googleVerified: false
          });
          throw Error("Failed recaptcha");
        } else {
          //success
          console.log("SUCCESS")
          validate.googleVerified = ""
          this.setState({
            validate,
            googleVerified: true
          })
        }
      })
      .catch((error) => {
        console.log("caught error", error);
        // handle errors and timeout error
        validate.googleVerified = "has-danger"
        this.setState({
          validate,
          googleVerified: false
        });
      });
  }
  
  // Method to update field values in state on change
  handleChange(e) {
    const target = e.target;
    const name = target.name
    const value = target.type === "checkbox" ? target.checked : target.value;
    
    // Update value in state
    this.setState({
      [name]: value,
    });
  }
  
  // Method to handle form submit
  async handleSubmit(e) {
    e.preventDefault();
    
    const {validate} = this.state;
    
    let formData = {};
    for (let key in this.state) {
      formData[key] = this.state[key];
    }
    formData["form"] = "music-play-day";
    
    const myInit = {
      body: {
        formData
      },
    };
    
    const res = await API.post("LKEducationAPI", "/LKMusicPlayDayLondon", myInit)
      .then((response) => {
        console.log(response)
        const event = response.event
        
        // If there are errors update validation state
        if (event.errors !== false && event.errors !== undefined) {
          // Scroll top top of form
          const domNode = ReactDOM.findDOMNode(this.form.current)
          window.scrollTo({
            top: domNode.offsetTop,
            behavior: "smooth"
          })
          
          const {validate} = this.state
          
          for (let key in event.errors) {
            if (event.errors[key] === true) {
              validate[key] = "has-danger"
            } else {
              validate[key] = event.errors[key]
            }
            
            this.setState({validate});
          }
          
          //re-enable the button
          this.setState({
            sendingFormRequest: false,
            submitBtnCopy: "Submit"
          });
          
        } else {
          if (event.success === true) {
            this.setState({
              success: true,
              submitBtnCopy: "Sending form"
            });
          }
          if (typeof event.MessageId !== "undefined") {
            navigate("/thank-you");
          } else {
            console.log(event.code)
          }
          // navigate("/thank-you");
        }
        
        //re-enable the button
        this.setState({
          sendingFormRequest: false,
          submitBtnCopy: "Submit"
        });
        
        // Scroll top top of form
        const domNode = ReactDOM.findDOMNode(this.form.current)
        window.scrollTo({
          top: domNode.offsetTop,
          behavior: "smooth"
        })
      })
  }
  
  openDialog = (e) => {
    if (typeof window !== 'undefined' && typeof window.__dataLayer !== 'undefined') {
      window.__dataLayer.OneTrustDialog(e)
    }
  }
  
  render() {
    
    return (
      <Layout slug="sunrise-scheme" title="Music Play Day Application Form">
        <HeaderWrapper>
          <Container fluid={true}>
            <Container>
              <HeaderContent>
                <div className="items">
                  <h1 className="pb-3">The Lion King inspired Music Play Day</h1>
                  <p className="pb-2">Making live theatre accessible to young people and schools</p>
                </div>
                <Link className="items" to="/"><img src={manchester}
                                                    alt="The Lion King - Lyceum Theatre, London"/></Link>
              </HeaderContent>
            </Container>
          </Container>
        </HeaderWrapper>
 
              <CompetitionOver>
                <p>The Lion King Music Play Day is your chance to experience what it's like to play the iconic music from the Award-wining musical.</p>
                <p>Applications for Music Play Day 2024 have now closed.</p>
              </CompetitionOver>
         
      </Layout>
    )
  }
  
}

export default MusicPlayDayForm


{/* <FormTheme>
          <Container fluid={true}>
            <Container>
              <>
                <h2 className="mb-3">Application Form</h2>
                <Row>
                  <Col xs={12}>
                    <p>
                      The Lion King Music Play Day is your chance to experience what it's like to play the iconic music
                      from the Award-wining musical.<br/>
                      This is your chance to work side by side with our musicians from The Lion King touring production.
                      Members of the orchestra will give instrument specific masterclasses throughout the day and help
                      you work towards a brief performance at the end of the day.
                    </p>
                    
                    <p>Our aim is to inspire and encourage students who enjoy music regardless of age and will give an insight into our daily work as professional musicians. </p>
                    <p>Do this by filling in the online form below.</p>
                    <p><strong>Key information you need to know</strong></p>
                    <ul>
                      <li>The Lion King Music Play Day</li>
                      <li>Monday 27th May 2024 9:30am – 3:30pm (approx.)</li>
                      <li>Lyceum Theatre, London</li>
                      <li>The closing date for applications is Thursday 28th March 10am</li>
                    </ul>
                  
                  </Col>
                </Row>
                {!this.state.cookieAccepted ?
                  <div className="text-center">
                    <p><strong>Note: To be able to see the form below and submit your details, you must accept the
                      cookies.</strong></p>
                    <a className="no-interstitial-check btn--red btn--block" onClick={(e) => this.openDialog(e)}
                       href="#" role="button">
                      Manage Your Cookie Settings
                    </a>
                  </div>
                  :
                  <React.Fragment>
                    <h2 className="mb-3">Music tutor details</h2>
                    <Form onSubmit={(e) => this.handleSubmit(e)} noValidate ref={this.form}>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="name">Name</Label>
                            <Input
                              type="text"
                              name="name"
                              id="name"
                              value={this.state.name}
                              // valid={this.state.validate.name === "has-success"}
                              // invalid={this.state.validate.name === "has-danger"}
                              onChange={e => {
                                this.handleChange(e)
                                // this.validateRequired(e)
                              }}
                            />
                            <FormFeedback>
                              Name is required
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="email">Email</Label>
                            <Input
                              type="email"
                              name="email"
                              id="email"
                              value={this.state.email}
                              // valid={this.state.validate.email === "has-success"}
                              // invalid={this.state.validate.email === "has-danger"}
                              onChange={e => {
                                this.handleChange(e)
                                // this.validateRequired(e)
                                this.validateEmail(e)
                              }}
                            />
                            <FormFeedback>
                              {this.state.validate.emailErrMsg}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <h2 className="mb-3">Participant details</h2>
                      
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="participantName">Full Name*</Label>
                            <Input
                              type="text"
                              name="participantName"
                              id="participantName"
                              value={this.state.participantName}
                              valid={this.state.validate.participantName === "has-success"}
                              invalid={this.state.validate.participantName === "has-danger"}
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                              }}
                            />
                            <FormFeedback>
                              Full Name is required
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="email">Email (if under 18 please enter parent/guardian email)*</Label>
                            <Input
                              type="participantEmail"
                              name="participantEmail"
                              id="participantEmail"
                              value={this.state.participantEmail}
                              valid={this.state.validate.participantEmail === "has-success"}
                              invalid={this.state.validate.participantEmail === "has-danger"}
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                                this.validateEmail(e)
                              }}
                            />
                            <FormFeedback>
                              {this.state.validate.emailErrMsg}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        
                        <Col md={6}>
                          <FormGroup>
                            <Label for="name">Phone number*</Label>
                            <Input
                              type="text"
                              name="phoneNumber"
                              id="phoneNumber"
                              value={this.state.phoneNumber}
                              valid={this.state.validate.phoneNumber === "has-success"}
                              invalid={this.state.validate.phoneNumber === "has-danger"}
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                                this.validatePhone(e, "phoneNumber", true)
                              }}
                            />
                            <FormFeedback>
                              {this.state.validate.phoneErrMsg}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="consent" check
                                   className="font-normal font-weight-light checkbox-btn">
                              If participant is under 18, I give my consent to the named above to take part in Music
                              Play Day.
                              <Input
                                type="checkbox"
                                name="consent"
                                id="consent"
                                value={this.state.consent}
                                valid={this.state.validate.consent === 'has-success'}
                                invalid={this.state.validate.consent === 'has-danger'}
                                onChange={e => {
                                  this.handleChange(e)
                                  this.validateRequiredCheckbox(e)
                                }}
                              />
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="instrumentsPlayed">Instrument*</Label>
                            <Input
                              type="select"
                              name="instrumentsPlayed"
                              id="instrumentsPlayed"
                              value={this.state.instrumentsPlayed}
                              valid={this.state.validate.instrumentsPlayed === "has-success"}
                              invalid={this.state.validate.instrumentsPlayed === "has-danger"}
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                              }}
                            >
                              <option value="">Select</option>
                              <option value="Soloist">Soloist</option>
                              <option value="Choir">Choir</option>
                              <option value="Marimba & Percussion">Marimba & Percussion</option>
                              <option value="Drums">Drums</option>
                              <option value="Violin">Violin</option>
                              <option value="Viola">Viola</option>
                              <option value="Cello">Cello</option>
                              <option value="French Horn">French Horn</option>
                              <option value="Trombone">Trombone</option>
                              <option value="Guitar">Guitar</option>
                              <option value="Electric Bass Guitar">Electric Bass Guitar</option>
                              <option value="Flute">Flute</option>
                              <option value="Clarinet">Clarinet</option>
                              <option value="Keyboard">Keyboard</option>
                            </Input>
                            <FormFeedback>
                              Instrument needs to be selected
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="grade">Grade* (Ideally grade 5 plus)</Label>
                            <Input
                              type="text"
                              pattern="[0-9]*"
                              name="grade"
                              id="grade"
                              onInput={e => this.handleChangeNumbers(e, "grade", this.state.grade)}
                              value={this.state.grade}
                              valid={this.state.validate.grade === "has-success"}
                              invalid={this.state.validate.grade === "has-danger"}
                              onChange={e => {
                                this.validateAmount(e, "grade", "gradeErrMsg", 70, "Grade field is required")
                              }}
                            />
                            <FormFeedback>
                              {this.state.validate.gradeErrMsg}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="questionForMusician">Do you have any questions you would like to ask our
                              musicians on the day?</Label>
                            <Input
                              type="textarea"
                              rows={4}
                              name="questionForMusician"
                              id="questionForMusician"
                              value={this.state.questionForMusician}
                              onChange={e => {
                                this.handleChange(e)
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="accessRequirements">Please let us know if you have any access
                              requirements</Label>
                            <Input
                              type="textarea"
                              rows={4}
                              name="accessRequirements"
                              id="accessRequirements"
                              value={this.state.accessRequirements}
                              onChange={e => {
                                this.handleChange(e)
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col md={12}>
                          <p>The information provided in this application form will be subject to <strong><a
                            href="https://disneyprivacycenter.com/privacy-policy-translations/english/" target="_blank"
                            rel="noopener noreferrer">Disney's Privacy Policy</a></strong> and will be used solely for the
                            purpose of assessing your application.</p>
                          <p>Disclaimer: by applying for these places I am committing to being able to attend/bring
                            group</p>
                        </Col>
                      </Row>
                      
                      <Row className="mb-4 mt-2">
                        <Col md={4}>
                          <SubmitButton
                            className="g-recaptcha btn--red btn--block"
                            data-sitekey={captchaSiteKey}
                            data-action="submit"
                            onClick={(e) => this.handleSubmit(e)}
                          >Submit</SubmitButton>
                        </Col>
                      </Row>
                      <h2>Terms & Conditions</h2>
                      <Row>
                        <Col xs={12}>
                          <p>Please email any queries to <strong><a
                            href="mailto:theatreeducationuk@disney.co.uk">theatreeducationuk@disney.co.uk</a></strong> &copy;Disney. <br/>
                            <strong>
                            <a href="https://disneyprivacycenter.com/privacy-policy-translations/english/"
                               target="_blank"
                               rel="noopener noreferrer">https://disneyprivacycenter.com/privacy-policy-translations/english/</a></strong>
                          </p>
                        </Col>
                      </Row>
                    </Form>
                    <ReCaptcha
                      sitekey={captchaSiteKey}
                      action="musicPlayDayForm"
                      verifyCallback={this.onGoogleVerify}
                    />
                  </React.Fragment>
                }
              </>
            </Container>
          </Container>
        </FormTheme> */}